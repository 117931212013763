// theme.js
import { createTheme } from '@mui/material/styles';

const generateTheme = (primaryColor, secondaryColor, backgroundColor, iconColor, textColor, secondaryText, paperColor, infoColor) => createTheme({
  palette: {
    mode: 'dark',
    primary: { main: primaryColor },
    secondary: { main: secondaryColor },
    background: { default: backgroundColor, paper: paperColor },
    text: {
      primary: textColor,
      secondary: secondaryText,
    },
    divider: primaryColor,
    icon: {
      main: iconColor,
      hover: primaryColor,
    },
    info: {
      main: infoColor,
    },
  },
  typography: {
    fontFamily: '"Courier New", Courier, monospace',
    h1: { fontWeight: 700, fontSize: '3rem', color: primaryColor },
    h2: { fontWeight: 700, fontSize: '2.5rem', color: primaryColor },
    h3: { fontWeight: 700, fontSize: '2rem', color: primaryColor },
    h4: { fontWeight: 700, fontSize: '1.75rem', color: primaryColor },
    h5: { fontWeight: 700, fontSize: '1.5rem', color: primaryColor },
    h6: { fontWeight: 700, fontSize: '1.25rem', color: primaryColor },
    body1: { color: textColor },
    body2: { color: secondaryText },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
        },
        contained: {
          backgroundColor: primaryColor,
          color: '#ffffff',
          '&:hover': { backgroundColor: '#1976d2' },
        },
        outlined: {
          borderColor: primaryColor,
          color: primaryColor,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': { color: textColor },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: primaryColor },
            '&:hover fieldset': { borderColor: '#64b5f6' },
            '&.Mui-focused fieldset': { borderColor: '#90caf9' },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: paperColor,
          color: textColor,
          borderRadius: '12px',
        },
      },
    },
  },
});

export default generateTheme;
