import React, { useState } from 'react';
import { Box, Typography, Paper, IconButton, Collapse, useMediaQuery, Button } from '@mui/material';
import { ChromePicker } from 'react-color';
import { FaTimes, FaPalette } from 'react-icons/fa';

const ColorPaletteManager = ({ onColorChange }) => {
  const [open, setOpen] = useState(false);
  const [primaryColor, setPrimaryColor] = useState('#2196f3');
  const [secondaryColor, setSecondaryColor] = useState('#90caf9');
  const [backgroundColor, setBackgroundColor] = useState('#212121');
  const [iconColor, setIconColor] = useState('#ffffff');
  const [textColor, setTextColor] = useState('#ffffff');
  const [secondaryText, setSecondaryTextColor] = useState('#bdbdbd');
  const [paperColor, setPaperColor] = useState('#424242');
  const [infoColor, setInfoColor] = useState('#1e88e5'); 

  const handleColorChange = (color, type) => {
    switch (type) {
      case 'primary':
        setPrimaryColor(color.hex);
        break;
      case 'secondary':
        setSecondaryColor(color.hex);
        break;
      case 'background':
        setBackgroundColor(color.hex);
        break;
      case 'icon':
        setIconColor(color.hex);
        break;
      case 'text':
        setTextColor(color.hex);
        break;
      case 'secondaryText':
        setSecondaryTextColor(color.hex);
        break;
      case 'paper':
        setPaperColor(color.hex);
        break;
      case 'info':
        setInfoColor(color.hex);
        break;
      default:
        break;
    }
    onColorChange(type, color.hex);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const generateThemeFile = () => {
    const theme = `
import { createTheme } from '@mui/material/styles';

const theme = () => createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '${primaryColor}' },
    secondary: { main: '${secondaryColor}' },
    background: { default: '${backgroundColor}', paper: '${paperColor}' },
    text: {
      primary: '${textColor}',
      secondary: '${secondaryText}',
    },
    divider: '${primaryColor}',
    icon: {
      main: '${iconColor}',
      hover: '${primaryColor}',
    },
    info: {
      main: '${infoColor}',
    },
  },
  typography: {
    fontFamily: '"Courier New", Courier, monospace',
    h1: { fontWeight: 700, fontSize: '3rem', color: '${primaryColor}' },
    h2: { fontWeight: 700, fontSize: '2.5rem', color: '${primaryColor}' },
    h3: { fontWeight: 700, fontSize: '2rem', color: '${primaryColor}' },
    h4: { fontWeight: 700, fontSize: '1.75rem', color: '${primaryColor}' },
    h5: { fontWeight: 700, fontSize: '1.5rem', color: '${primaryColor}' },
    h6: { fontWeight: 700, fontSize: '1.25rem', color: '${primaryColor}' },
    body1: { color: '${textColor}' },
    body2: { color: '${secondaryText}' },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '8px',
        },
        contained: {
          backgroundColor: '${primaryColor}',
          color: '#ffffff',
          '&:hover': { backgroundColor: '#1976d2' },
        },
        outlined: {
          borderColor: '${primaryColor}',
          color: '${primaryColor}',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': { color: '${textColor}' },
          '& .MuiOutlinedInput-root': {
            '& fieldset': { borderColor: '${primaryColor}' },
            '&:hover fieldset': { borderColor: '#64b5f6' },
            '&.Mui-focused fieldset': { borderColor: '#90caf9' },
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: '${paperColor}',
          color: '${textColor}',
          borderRadius: '12px',
        },
      },
    },
  },
});

export default theme;
`;
    const blob = new Blob([theme], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'theme.js';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <IconButton
        onClick={handleToggle}
        sx={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 1301,
          backgroundColor: '#38a5ff',
          color: '#fff',
          borderRadius: '50%',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
          transition: 'background-color 0.3s, transform 0.3s',
          transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
          '&:hover': {
            backgroundColor: '#1976d2',
          },
        }}
      >
        {open ? <FaTimes size={20} /> : <FaPalette size={20} />}
      </IconButton>

      <Collapse
        in={open}
        timeout="auto"
        sx={{
          position: 'fixed',
          bottom: '1rem',
          right: '1rem',
          zIndex: 1300,
          width: isMobile ? '90%' : '320px',
          maxWidth: '400px',
          transition: 'transform 0.3s ease-in-out',
          transform: open ? 'translateY(0)' : 'translateY(100%)',
        }}
      >
        <Paper
          sx={{
            padding: '1rem',
            backgroundColor: '#333',
            borderRadius: '16px',
            boxShadow: '0 6px 16px rgba(0, 0, 0, 0.3)',
            overflowY: 'auto',
            maxHeight: '80vh',
            transform: open ? 'scale(1)' : 'scale(0)',
            transition: 'transform 0.3s ease-in-out',
          }}
        >
          <Box display="flex" flexDirection="column" gap={2}>
            <Typography variant="h6" color="white" gutterBottom>
              Color Palette Manager
            </Typography>

            {[
              { label: 'Primary Color', color: primaryColor, type: 'primary' },
              { label: 'Secondary Color', color: secondaryColor, type: 'secondary' },
              { label: 'Background Color', color: backgroundColor, type: 'background' },
              { label: 'Icon Color', color: iconColor, type: 'icon' },
              { label: 'Text Color', color: textColor, type: 'text' },
              { label: 'Secondary Text Color', color: secondaryText, type: 'secondaryText' },
              { label: 'Paper Color', color: paperColor, type: 'paper' },
              { label: 'Info Color', color: infoColor, type: 'info' }, 
            ].map(({ label, color, type }) => (
              <Box key={type} mb={2}>
                <Typography variant="body2" color="white" gutterBottom>
                  {label}
                </Typography>
                <ChromePicker
                  color={color}
                  onChange={(color) => handleColorChange(color, type)}
                  disableAlpha
                  styles={{
                    default: {
                      picker: {
                        width: '100%',
                        boxShadow: 'none',
                        borderRadius: '8px',
                      },
                    },
                  }}
                />
              </Box>
            ))}

            <Button
              variant="contained"
              color="primary"
              onClick={generateThemeFile}
              sx={{ mt: 2 }}
            >
              Download theme.js
            </Button>
          </Box>
        </Paper>
      </Collapse>
    </>
  );
};

export default ColorPaletteManager;
