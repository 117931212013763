import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import ColorPaletteManager from './ColorPaletteManager';
import generateTheme from './theme';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import { Helmet } from 'react-helmet';


const App = () => {
  const [theme, setTheme] = useState(generateTheme('#2196f3', '#90caf9', '#212121', '#ffffff', '#ffffff', '#bdbdbd','#424242', '#1e88e5'));
  const [showColorPalette, setShowColorPalette] = useState(true);
  const handleColorChange = (type, color) => {
    const currentPalette = theme.palette;
    const newTheme = generateTheme(
      type === 'primary' ? color : currentPalette.primary.main,
      type === 'secondary' ? color : currentPalette.secondary.main,
      type === 'background' ? color : currentPalette.background.default,
      type === 'icon' ? color : currentPalette.icon.main,
      type === 'text' ? color : currentPalette.text.primary,
      type === 'secondaryText' ? color : currentPalette.text.secondary,
      type === 'paper' ? color : currentPalette.background.paper,
      type === 'info' ? color : currentPalette.info.main
    );
    setTheme(newTheme);
  };

  return (
    <ThemeProvider theme={theme}>
      {showColorPalette && (
        <ColorPaletteManager
          onColorChange={handleColorChange}
          onClose={() => setShowColorPalette(false)}
        />
      )}
      <Helmet>
        <title>React Editable Portfolio - Modern Web Design</title>
        <meta name="description" content="Create a modern and customizable portfolio with React. Enhance user experience with color palette management and dynamic theme support." />
        <meta name="keywords" content="React, Portfolio, Web Design, Color Palette, Theme Management, 0memo07, memo, mehmet, mehmet Portfolio, Customizable Portfolio, Frontend Development, UI/UX Design, JavaScript, Responsive Design, React Components, Dynamic Themes, MUI, Material-UI, Mehmet Studio, Web Development, Developer Portfolio, Modern Web Design, Personal Branding, Interactive Design, Web Applications, React Hooks, Mobile-Friendly Design, Design Systems, Theming in React, React Projects, Full-Stack Development, Mehmet Personal Portfolio" />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Your Name" />
        <meta property="og:title" content="React Editable Portfolio - Modern Web Design" />
        <meta property="og:description" content="Create a modern portfolio with React and personalize the user experience with customizable color palette management." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://mehmet.studio" />
        <meta property="og:image" content="https://mehmet.studio/PortfolioDevices.png" />
        <meta property="og:image:alt" content="React Editable Portfolio preview on devices" />
        <meta name="theme-color" content="#2196f3" />
      </Helmet>
      <Home />
      <About />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
};

export default App;
